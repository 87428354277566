<template>
  <div class="main">
    <!-- 面包屑导航 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品生产信息</el-breadcrumb-item>
      <el-breadcrumb-item>上传产品生产信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图区 -->

    <el-card class="box-card">
      <!-- 搜索与添加区域 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input
            clearable
            v-model="queryInfo.query"
            @clear="getDataList"
            placeholder="请输入企业名称"
            class="input-with-select"
          >
            <el-button
              @click="getDataList"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <!-- <el-button type="primary" @click="addInfo">添加品种信息</el-button> -->
        </el-col>
      </el-row>
      
      <!-- 数据列表区域 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="编号" type="index"></el-table-column>
        <el-table-column label="企业名称" prop="j_dwmc"></el-table-column>
        <el-table-column label="企业信用代码" prop="j_licence"></el-table-column>
        <el-table-column label="企业负责人" prop="j_name"></el-table-column>
        <el-table-column label="企业邮箱" prop="j_email"></el-table-column>
        <el-table-column label="企业电话" prop="j_mobile"></el-table-column>
        <el-table-column  label="审核状态" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.j_status==1" type="success">审核通过</el-tag>
            <el-tag v-if="scope.row.j_status==2" type="danger" >审核未通过</el-tag>
            <el-tag v-if="scope.row.j_status==0" type="warning" >等待审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column  label="审核" width="200">
          <template slot-scope="scope" >
              <div >
                  <el-tooltip effect="dark" content="审核通过" placement="top-start">
              <el-button
                @click="shenhe(scope.row,1)"
                type="success"
                icon="el-icon-check"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="审核不通过" placement="top-start">
              <el-button
                @click="shenhe(scope.row,2)"
                type="danger"
                icon="el-icon-close"
              ></el-button>
            </el-tooltip>

              </div>
            <!-- <div v-if="scope.row.j_status==1">
                <el-tag  type="success">暂无操作</el-tag>
            </div> -->
            
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <!-- 插槽自定义样式 -->
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="查看" placement="top-start">
              <el-button
                @click="getInfo(scope.row)"
                type="primary"
                icon="el-icon-view"
              ></el-button>
            </el-tooltip>
            
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        background
        layout="prev, pager, next,total"
        :total="total"
        :page-size="queryInfo.pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!-- 新增产品生产dialog -->
     
    </el-card>
  </div>
</template>

<script>
const devices = [
  { type: 1, name: "审定品种" },
  { type: 2, name: "认定品种" },
  { type: 3, name: "登记品种" },
];
export default {
  data() {
    return {
      rules: {
        chandi: [{ requir: true, message: "请选择产地名称", trigger: "blur" }],
        cpmc: [{ requir: true, message: "请选择产品名称", trigger: "blur" }],
        scz: [{ requir: true, message: "请输入生产负责人", trigger: "blur" }],
        rq: [{ requir: true, message: "请选择日期", trigger: "blur" }],
      },
      tokenStr: "",
      queryInfo: {
        token: "",
        query: "",
        rec_class:'',
        page: 1,
        pageSize: 8,
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      DialogVisible: false,
      addFormInfo: {
        id: "",
        token: "",
        name: "",
        area: "",
        year: "",
        class: "",
        number: "",
        company: "",
        file_number: "",
        content: "",
      },
      title: "品种信息",
      selectchandi: [],
      selectProduct: [],
    };
  },
  created() {
    this.tokenStr = window.sessionStorage.getItem("token");
    this.getDataList();
  },
  filters: {
    //过滤器
    devType(res) {
      // 传入当前操作的行对象
      const d = devices.find((obj) => obj.type == res);
      console.log(d);
      return d ? d.name : null;
    },
  },
  methods: {
      async shenhe(row,status){
          let data={
              "id":row.id,
              "j_status":status
          }
          const {data:res} = await this.$http.post("statusQy",data)
          if(res.code==0){
              this.$message.success("审核完成")
              this.getDataList
          }
      },
    getInfo(row) {
      console.log(row);
      let datarow = JSON.parse(JSON.stringify(row));
      this.$router.push("qyinfo?id="+datarow.id)
    },
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("getUserList", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
      //  新增产地，下拉框数据
      // const {data:selectRes} =await this.$http.post('SelectPlace',{token:this.tokenStr})
      // this.selectchandi = selectRes.data;
      //  //  产品名称，下拉框数据
      // const {data: selectProductRes} =await this.$http.post('SelectProduction',{token:this.tokenStr})
      // this. selectProduct =  selectProductRes.data;
      // console.log(selectProductRes)
    },
    // 分页功能--监听当前页码值 改变事件
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
      // this.getDataList();
    },
    addInfo() {
      this.DialogVisible = true;
      this.title = "添加品种信息";
    },
    FormSubmit() {
      console.log(this.addFormInfo);
      this.addFormInfo.token = this.tokenStr;
      let id = this.addFormInfo.id;
      if (!id) {
        this.$http.post("addPinZhong", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("添加信息成功");
            this.getDataList();
          }
        });
      } else {
        this.$http.post("addPinZhong", this.addFormInfo).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("修改信息成功");
            this.getDataList();
          }
        });
      }
      this.DialogVisible = false;
      // this.getDataList();
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
    },
    async delInfo(id) {
      const confirmRes = await this.$confirm(
        "此操作将永久删除该条信息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmRes != "confirm") {
        return this.$message.info("取消删除");
      }
      const res = await this.$http.post("delPinZhong", {
        token: this.tokenStr,
        id: id,
      });
      if (res.status !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.getDataList();
      console.log(res);
    },
    shending() {
      this.queryInfo.rec_class=1
      this.getDataList()
    },
    rending() {
      this.queryInfo.rec_class=2
      this.getDataList()
    },
    dengji() {
      this.queryInfo.rec_class=3
      this.getDataList()
    },
    rest(){
      this.queryInfo.rec_class=''
      this.getDataList()
    }
  },
};
</script>

<style scoped lang="less">
.drawer-footer {
  text-align: center;
  width: 100%;
}
.el-breadcrumb {
  height: 30px;
}
.el-pagination {
  margin-top: 10px;
}
.main {
  background-color: #000d4a;
}
// .box-card{
//    background-color: #000d4a;
// }
</style>

